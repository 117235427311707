<template>
	<!-- Step 2 -->
	<div class="step-3" v-loading="loading" element-loading-spinner="el-icon-loading">
		<div class="body-quotation">
			<div class="model">
				<div v-for="labelModel in labelModels" :key="labelModel.id" v-text="labelModel"></div>
			</div>
			<div class="res-model">
				<div v-text="model.name"></div>
				<div v-text="model.rut"></div>
				<div v-text="model.phone"></div>
				<div v-text="model.email"></div>
			</div>
		</div>

		<div class="table-quotation">
			<!-- Error -->
			<div v-on="emitApolloError(error)" v-if="error" class="error apollo">
				<el-alert :title="error" :closable="false" type="error" description="Puede que haya un error al cargar los datos del componente o establecer una conexion con base de datos." show-icon>
				</el-alert>
			</div>

			<el-table
				stripe
				show-summary
				v-on="dataTable(tableData)"
				:empty-text="tableData.length === 0 ? 'Sin resultados' : 'Data'"
				:data="tableData"
				style="width: 100%"
				:summary-method="getTotal"
				max-height="275"
			>
				<el-table-column prop="cant" label="Cant. Mts" width="100"> </el-table-column>
				<el-table-column prop="detail" label="Detalle de la solicitud"> </el-table-column>
				<!-- <el-table-column prop="price" label="Precio" width="100"> </el-table-column>
        <el-table-column prop="total" label="Total" min-width="125"> </el-table-column> -->
			</el-table>
		</div>
	</div>
</template>

<script>
import obtenerTarifa from "../../../graphql/ObtenerTarifa.gql";

export default {
	name: "QuotePreview",
	props: ["model", "loading"],
	mounted() {
		this.quote();
	},
	data() {
		return {
			tableData: [],
			emptyData: "Vacio",
			error: null,
			labelModels: ["Nombre / Razón Social", "Rut", "Teléfono", "Email"],
		};
	},
	methods: {
		async quote() {
			const result = await this.$apollo
				.query({
					query: obtenerTarifa,
					variables: {
						region_id: this.model.region,
						diametro_id: this.model.diameter,
						producto_id: this.model.additional_products,
					},
				})
				.catch(error => {
					this.error = JSON.stringify(error.message);
				});

			const itemRows = [];
			const tarifas = result.data.tarifa;

			for (let index = 0; index < tarifas.length; index++) {
				const element = tarifas[index];
				if (typeof element != "undefined") {
					const valor = element.valor;
					const productsName = element.producto.nombre;
					const productMultiplicable = element.producto.multiplicable;
					const diametersName = element.diametro.descripcion;
					const meters = productMultiplicable ? this.model.meters : 1;

					const item = {
						cant: meters,
						detail: productsName + " " + diametersName,
						price: "$" + this.formatMoney(valor),
						total: "$" + this.formatMoney(valor * meters),
					};

					itemRows.push({ id: Number(element.producto.id), valorProduct: valor, totalProduct: valor * meters, ...item });
				}
			}

			this.tableData = itemRows;
		},
		getTotal(param) {
			const { columns, data } = param;
			const sums = [];
			columns.forEach((column, index) => {
				if (index === 0) {
					sums[index] = "";
					return;
				}

				if (index >= 1 && index <= 2) {
					sums[index] = "";
					return;
				}

				const values = data.map(item => this.parseMoney(item[column.property]));
				if (!values.every(value => isNaN(value))) {
					sums[index] =
						"$ " +
						this.formatMoney(
							values.reduce((prev, curr) => {
								const value = Number(curr);
								if (!isNaN(value)) {
									return prev + curr;
								} else {
									return prev;
								}
							}),
							0
						);
				} else {
					sums[index] = "N/A";
				}
			});
			return sums;
		},
		formatMoney(value) {
			value = isNaN(value) ? value : value.toString();
			value = value.replace(/[^\d]/g, "");
			value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
			return value;
		},
		parseMoney(value) {
			value = isNaN(value) ? value : value.toString();
			value = value.replace(/[^\d]/g, "");
			return Number(value);
		},
		dataTable(data) {
			this.$emit("dataTable", data.length);
			this.$emit("dataProducts", data);
		},
		emitApolloError(err) {
			this.$emit("apolloErrorEmit", err);
		},
	},
};
</script>
