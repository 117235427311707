<template>
  <div class="not-found">
    <div class="text border-r">404</div>
    <div class="text">NOT FOUND</div>
  </div>
</template>

<script>
export default {};
</script>

<style>
* {
  box-sizing: border-box;
  border: 0 solid #e2e8f0;
}
.not-found {
  display: flex;
  height: 100vh;
  background: #f7fafc;
  justify-content: center;
  align-items: center;
}

.text {
  letter-spacing: 0.05em;
  color: #a0aec0;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 0 solid #e2e8f0;
}

.border-r {
  border-right-width: 1px;
}

.uppercase {
  text-transform: uppercase;
}
</style>
