import "./registerServiceWorker";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/styles.css";

import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

import App from "./App.vue";
import ElementUI from "element-ui";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vue from "vue";
import { createProvider } from "./vue-apollo";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import router from "./router";
import store from "./store";

// Vue
Vue.config.productionTip = false;
// Element UI
Vue.use(ElementUI);
// FontAwesome
library.add(faEnvelope, faPhone, faWhatsapp);
Vue.component("font-awesome-icon", FontAwesomeIcon);

new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  render: h => h(App),
}).$mount("#app");
