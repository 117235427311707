<template>
	<!-- Content -->
	<div class="content">
		<div class="main" :class="'step-' + activeStep">
			<el-form :model="model" ref="model" label-position="top" :rules="rules" status-icon>
				<!-- PROGRESSBAR -->
				<el-progress :percentage="percentage" :color="progressbar" :stroke-width="2" :show-text="false"></el-progress>

				<!-- Header Step -->
				<HeaderStep :activeStep="activeStep" :totalStep="totalStep" />

				<!-- Step 1 -->
				<template v-if="activeStep == 1">
					<PersonalInformation :model="model" ref="personalInformation" @apolloErrorEmit="resEmitApolloError($event)" />
				</template>

				<!-- Step 2 -->
				<template v-if="activeStep == 2">
					<ProductSelection :model="model" ref="productSelection" @apolloErrorEmit="resEmitApolloError($event)" @minMeters="emitMinMeters($event)" />
				</template>

				<!-- Step 3 -->
				<template v-if="activeStep == 3">
					<QuotePreview :model="model" :loading="loading" ref="quotePreview" @apolloErrorEmit="resEmitApolloError($event)" @dataProducts="dataProducts($event)" @dataTable="resDataTable($event)" />
				</template>

				<!-- Bottom Form -->
				<el-form-item class="fixBottom">
					<el-button type="primary" class="prev" @click.prevent="prevStep" icon="el-icon-back" :disabled="apolloError || loading" v-if="activeStep !== 1"> Anterior </el-button>
					<el-button type="primary" class="next" @click.prevent="nextStep('model')" :disabled="apolloError" v-if="activeStep !== totalStep">
						Siguiente
						<i class="el-icon-right"></i>
					</el-button>
					<el-button type="primary" class="send" @click.prevent="sendCotizacion" :disabled="apolloError || dataTable || loading" v-if="activeStep === 3">
						Envia cotización
						<i class="el-icon-check"></i>
					</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import HeaderStep from "./HeaderStep";
import PersonalInformation from "./PersonalInformation";
import ProductSelection from "./ProductSelection";
import QuotePreview from "./QuotePreview";

import productos from "../../../graphql/Productos.gql";
import primerProducto from "../../../graphql/unProducto.gql";
import crearCotizacion from "../../../graphql/CrearCotizacion.gql";

const { validate, clean } = require("rut.js");

export default {
	name: "Content",
	components: {
		HeaderStep,
		PersonalInformation,
		ProductSelection,
		QuotePreview,
	},
	mounted() {
		this.setProducts();
	},
	data() {
		let validateRut = (_, value, callback) => {
			validate(value) ? callback() : callback(new Error("El rut ingresado es inválido"));
		};

		let validateMeters = (_, value, callback) => {
			value < this.minMeters ? callback(new Error(`El valor tiene que ser mayor a ${this.minMeters}`)) : callback();
		};

		let validateNumber = (_, value, callback) => {
			value < 0 ? callback(new Error("Sólo se recibe números positivos.")) : callback();
		};

		let validateMinPhone = (_, value, callback) => {
			value.toString().length < 9 ? callback(new Error(`El mínimo de números son 9`)) : callback();
		};

		return {
			totalStep: 3,
			activeStep: 1,
			percentage: 0,
			loading: false,
			dataTable: false,
			apolloError: false,
			minMeters: "",
			products: "",
			model: {
				name: "",
				rut: "",
				giro: "",
				billing_address: "",
				work_location: "",
				phone: "",
				email: "",
				region: "",
				commune: "",
				meters: "",
				diameter: "",
				additional_products: [],
			},
			rules: {
				name: [{ required: true, message: "El nombre es requerido.", trigger: "blur" }],
				rut: [
					{ required: true, message: "El rut es requerido." },
					{ validator: validateRut, trigger: "blur" },
				],
				giro: [{ required: true, message: "El giro / Actividad Económica o particular es requerida.", trigger: "blur" }],
				billing_address: [{ required: true, message: "La dirección de facturación es requerida.", trigger: "blur" }],
				work_location: [{ required: true, message: "La ubicación de la obra es requerida.", trigger: "blur" }],
				phone: [
					{ required: true, message: "El teléfono es requerido." },
					{ type: "number", message: "Éste campo sólo recibe números." },
					{ validator: validateNumber, trigger: "change" },
					{ validator: validateMinPhone, trigger: "blur" },
				],
				email: [
					{ required: true, message: "El email es requerido.", trigger: "blur" },
					{ type: "email", message: "Por favor ingrese un email correcto.", trigger: ["blur", "change"] },
				],
				region: [{ required: true, message: "Es necesario seleccionar una Región", trigger: "change" }],
				commune: [{ required: true, message: "Es necesario seleccionar una Comuna", trigger: "change" }],
				meters: [
					{ required: true, message: "Los metros son requerido.", trigger: "blur" },
					{ type: "number", message: "Sólo números." },
					{ validator: validateMeters, trigger: "blur" },
				],
				diameter: [{ required: true, message: "Es necesario seleccionar un diámetro", trigger: "change" }],
				additional_products: [{ type: "array", required: true, message: "Es necesario seleccionar un producto como mínimo", trigger: "change" }],
			},
		};
	},
	methods: {
		async setProducts() {
			const res = await this.$apollo.query({ query: primerProducto });
			const producto = res.data.unProducto;
			this.model.additional_products.push(producto.id);

			//Productos
			// const resultProdutcs = await this.$apollo.query({ query: productos });
			// resultProdutcs.data.productos.map(producto => this.model.additional_products.push(producto.id));
		},
		dataProducts(data) {
			if (data.length > 0) {
				let products = [];
				data.map(e => products.push({ ...e }));
				this.products = products;
			}
		},
		sendCotizacion() {
			this.$confirm("Esta seguro que desea enviar esta cotización?", "Cotización", {
				confirmButtonText: "Enviar",
				cancelButtonText: "Cancelar",
				type: "warning",
			})
				.then(res => {
					if (res) {
						this.loading = true;
						const produtsList = [];
						this.products.map(product =>
							produtsList.push({
								producto_id: product.id,
								precio_neto: product.valorProduct,
								precio_total: product.totalProduct,
							})
						);

						this.$apollo
							.mutate({
								mutation: crearCotizacion,
								variables: {
									inputCabecera: {
										nombre: this.model.name,
										rut: clean(this.model.rut),
										giro: this.model.giro,
										direccion_facturacion: this.model.billing_address,
										ubicacion_obra: this.model.work_location,
										telefono: this.model.phone,
										email: this.model.email,
										metros: this.model.meters,
										comuna_id: this.model.commune,
										diametro_id: this.model.diameter,
									},
									inputProductos: produtsList,
								},
							})
							.then(res => {
								if (res) {
									const cotizacion = res.data.createCotizacion;
									setTimeout(() => {
										this.activeStep = 1;
										this.percentage = 0;
										this.model.name = "";
										this.model.rut = "";
										this.model.giro = "";
										this.model.billing_address = "";
										this.model.work_location = "";
										this.model.phone = "";
										this.model.email = "";
										this.model.region = "";
										this.model.commune = "";
										this.model.meters = "";
										this.model.diameter = "";
										this.model.additional_products = [];
										this.setProducts();
										this.$notify({
											title: "Éxito",
											dangerouslyUseHTMLString: true,
											message: `Se ha generado la cotización <strong>n° ${cotizacion.id}</strong> con éxito y se ha  enviado al correo <strong>${cotizacion.email}</strong>`,
											type: "success",
										});
										this.loading = false;
									}, 1000);
								}
							})
							.catch(err => {
								this.$notify.error({
									title: "Error",
									dangerouslyUseHTMLString: true,
									message: `No se ha podido enviar la cotización, ah ocurrido un error inesperado`,
								}),
									console.log(err);
							});
					}
				})
				.catch(err => console.log(err));
		},
		resEmitApolloError(err) {
			if (err) {
				this.apolloError = true;
			}
		},
		emitMinMeters(data) {
			this.minMeters = data;
		},
		resDataTable(data) {
			if (data === 0) {
				this.dataTable = true;
			} else {
				this.dataTable = false;
			}
		},
		nextStep(model) {
			this.$refs[model].validate(valid => {
				if (valid) {
					this.activeStep++;
					this.percentage += 50;
					if (this.percentage > 100) {
						this.percentage = 100;
					}
				} else {
					return false;
				}
			});
		},
		prevStep() {
			this.activeStep--;
			this.percentage -= 50;
			if (this.percentage < 0) {
				this.percentage = 0;
			}
		},
		progressbar(percentage) {
			if (percentage < 50) {
				return "#0098dd";
			} else if (percentage < 70) {
				return "#006d8b";
			} else {
				return "#67c23a";
			}
		},
	},
};
</script>
