<template>
  <div class="home">
    <Menu />
    <el-container class="container-home">
      <el-aside class="aside-home">
        <Aside />
      </el-aside>
      <el-main class="content-home">
        <Content />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Aside from "@/components/Aside/Aside";
import Content from "@/components/Content/Content";
import Menu from "@/components/Menu";
export default {
  name: "Home",
  components: {
    Aside,
    Content,
    Menu,
  },
};
</script>
