<template>
  <div>
    <div class="menu">
      <input class="input" type="checkbox" id="menuToggle" />
      <label class="label" for="menuToggle">
        <span class="hamburger"></span>
      </label>
      <nav class="menu-nav">
        <ul class="list">
          <li class="item" v-for="item in dataMenu" :key="item.id">
            <a class="link" :href="item.link" v-text="item.name"></a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  data() {
    const baseUrl = "http://www.pozosmanantial.cl/";
    return {
      dataMenu: [
        { id: 1, name: "Inicio", link: "/" },
        { id: 2, name: "Nuestros Recursos", link: baseUrl + "#recursos" },
        { id: 3, name: "Acerca de Nosotros", link: baseUrl + "#nosotros" },
        { id: 4, name: "Nuestros Servicios", link: baseUrl + "#servicios" },
        { id: 5, name: "Contacta con Nosotros", link: baseUrl + "#contacto" },
      ],
    };
  },
};
</script>
