<template>
	<!-- Description -->
	<p class="description" v-text="description"></p>
</template>

<script>
export default {
	name: "Description",
	data() {
		return {
			description: "Constucción y perforación de pozos profundos para el aprovechamiento de aguas subterráneas - Instalación de Sistemas de Bombeo - Pruebas de Bombeo.",
		};
	},
};
</script>
