<template>
	<!-- Step 2 -->
	<div class="step-2" ref="ProductSelection">
		<el-row :gutter="10">
			<el-col :span="10">
				<el-form-item prop="meters">
					<el-input v-model.number="model.meters" placeholder="Metros" maxlength="3" />
				</el-form-item>
			</el-col>

			<el-col :span="14">
				<template>
					<!-- Loading -->
					<div v-if="loading" element-loading-spinner="el-icon-loading" v-loading="loading" class="loading apollo"></div>

					<!-- Error -->
					<div v-on="emitErrorApollo(error)" v-else-if="error" class="error apollo">
						<el-alert :title="error" :closable="false" type="error" description="Puede que haya un error al cargar los datos del componente o establecer una conexion con base de datos." show-icon>
						</el-alert>
					</div>

					<!-- Data -->
					<div v-else-if="diametros" class="result apollo">
						<el-form-item prop="diameter">
							<el-select v-model="model.diameter" placeholder="Seleccione un Diámetro">
								<el-option v-for="diameter in newDiametros" :key="diameter.id" :label="diameter.descripcion" :value="diameter.id"></el-option>
							</el-select>
						</el-form-item>
					</div>
				</template>
			</el-col>
		</el-row>

		<ApolloQuery :query="require('../../../graphql/Productos.gql')">
			<template v-slot="{ result: { error, data }, isLoading }">
				<div v-if="isLoading" element-loading-spinner="el-icon-loading" v-loading="isLoading" class="loading apollo"></div>

				<div v-on="emitApolloError(error)" v-else-if="error" class="error apollo">
					<el-alert :title="error" :closable="false" type="error" description="Puede que haya un error al cargar los datos del componente o establecer una conexion con base de datos." show-icon>
					</el-alert>
				</div>

				<div v-else-if="data" class="result apollo">
					<el-row :gutter="10">
						<el-col :span="24">
							<el-form-item label="Seleccione los servicios opcionales que desea añadir: " prop="additional_products">
								<el-checkbox-group v-model="model.additional_products">
									<el-checkbox
										:disabled="additional_product.id == 1"
										v-for="additional_product in data.productos"
										:value="additional_product.id"
										:label="additional_product.id"
										:key="additional_product.id"
									>
										{{ additional_product.nombre }}
									</el-checkbox>
								</el-checkbox-group>
							</el-form-item>
						</el-col>
					</el-row>
				</div>
			</template>
		</ApolloQuery>
	</div>
</template>

<script>
import diametros from ".../../../graphql/Diametros.gql";
import obtenerRegion from "../../../graphql/ObtenerRegion.gql";

export default {
	name: "ProductSelection",
	props: ["model"],
	apollo: {
		diametros: {
			query: diametros,
			watchLoading(isLoading) {
				this.loading = isLoading;
			},
			error(error) {
				this.error = JSON.stringify(error.message);
			},
		},
	},
	data() {
		return {
			loading: null,
			error: null,
			diametros: [],
		};
	},
	mounted() {
		this.getMinMeters();
	},
	methods: {
		emitErrorApollo(err) {
			this.$emit("apolloErrorEmit", err);
		},
		async getMinMeters() {
			await this.$apollo
				.query({
					query: obtenerRegion,
					variables: {
						id: this.model.region,
					},
				})
				.then(res => {
					const minMeters = res.data.region.min_metros;
					this.$emit("minMeters", minMeters);
				})
				.catch(error => {
					this.error = JSON.stringify(error.message);
				});
		},
	},
	computed: {
		newDiametros() {
			if (this.diametros.length != 0) {
				const diametro = this.diametros.sort((a, b) => {
					if (a.valor > b.valor) return 1;
					if (a.valor < b.valor) return -1;
					return 0;
				});
				return diametro;
			}
			return [];
		},
	},
};
</script>
