<template>
	<!-- Step 1 -->
	<div class="step-1">
		<el-row :gutter="10">
			<el-col :span="14" :xs="24">
				<el-form-item prop="name">
					<el-input v-model="model.name" placeholder="Nombre / Razón Social" maxlength="100" />
				</el-form-item>
			</el-col>

			<el-col :span="10" :xs="24">
				<el-form-item prop="rut">
					<el-input v-model="model.rut" :input="formatRut(model.rut)" placeholder="Rut" maxlength="12" />
				</el-form-item>
			</el-col>
		</el-row>

		<el-row :gutter="10">
			<el-col :span="24">
				<el-form-item prop="giro">
					<el-input v-model="model.giro" placeholder="Giro / Actividad Económica o Particular" maxlength="200" />
				</el-form-item>
			</el-col>
		</el-row>

		<el-row :gutter="10">
			<el-col :span="24">
				<el-form-item prop="billing_address">
					<el-input v-model="model.billing_address" placeholder="Dirección de Facturación" maxlength="200" />
				</el-form-item>
			</el-col>
		</el-row>

		<el-row :gutter="10">
			<el-col :span="24">
				<el-form-item prop="work_location">
					<el-input v-model="model.work_location" placeholder="Ubicación de la Obra" maxlength="200" />
				</el-form-item>
			</el-col>
		</el-row>

		<el-row :gutter="10">
			<el-col :span="10" :xs="24">
				<el-form-item prop="phone">
					<el-input class="phone" v-model.number="model.phone" placeholder="Teléfono" maxlength="9" min="9">
						<template slot="prepend">+56</template>
					</el-input>
				</el-form-item>
			</el-col>

			<el-col :span="14" :xs="24">
				<el-form-item prop="email">
					<el-input v-model="model.email" placeholder="Email" maxlength="255" />
				</el-form-item>
			</el-col>
		</el-row>

		<template>
			<!-- Loading -->
			<div v-if="loading" element-loading-spinner="el-icon-loading" v-loading="loading" class="loading apollo"></div>

			<!-- Error -->
			<div v-on="emitApolloError(error)" v-else-if="error" class="error apollo">
				<el-alert :title="error" :closable="false" type="error" description="Puede que haya un error al cargar los datos del componente o establecer una conexion con base de datos." show-icon>
				</el-alert>
			</div>

			<!-- Data -->
			<div v-else-if="regiones" class="result apollo" id="result-apollo">
				<el-row :gutter="10">
					<el-col :span="24">
						<el-form-item prop="region">
							<el-select @change="emptySelectComunne()" v-model="model.region" placeholder="Seleccione una Región" no-data-text="Sin resultados" filterable no-match-text="Sin resultados">
								<el-option v-for="region in regiones" :key="region.id" :label="region.nombre" :value="region.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row :gutter="10">
					<el-col :span="24">
						<el-form-item prop="commune" id="commune">
							<el-select v-model="model.commune" placeholder="Seleccione una Comuna" no-data-text="Sin resultados" filterable no-match-text="Sin resultados">
								<template v-if="model.region != ''">
									<el-option v-for="commune in comunas" :key="commune.id" :label="commune.nombre" :value="commune.id"></el-option>
								</template>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
			</div>
		</template>
	</div>
</template>

<script>
const { format } = require("rut.js");
import regiones from "../../../graphql/Regiones.gql";

export default {
	name: "PersonalInformation",
	props: ["model"],
	apollo: {
		regiones: {
			query: regiones,
			watchLoading(loading) {
				this.loading = loading;
			},
			error(error) {
				this.error = JSON.stringify(error.message);
			},
		},
	},
	data() {
		return {
			loading: null,
			error: null,
			regiones: [],
		};
	},
	methods: {
		emitApolloError(error) {
			this.$emit("apolloErrorEmit", error);
		},
		formatRut(value) {
			const cant = value.toString().length;
			if (cant > 0) {
				this.model.rut = format(value);
			}
		},
		emptySelectComunne() {
			this.model.commune = "";
		},
	},
	computed: {
		comunas() {
			if (this.regiones.length != 0) {
				const dataComunas = this.regiones.find(e => e.id == this.model.region);
				const comunas = dataComunas.comunas.sort((a, b) => {
					if (a.nombre > b.nombre) return 1;
					if (a.nombre < b.nombre) return -1;
					return 0;
				});
				return comunas;
			}
			return [];
		},
	},
};
</script>
