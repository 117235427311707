<template>
  <!-- Header Step -->
  <h1 class="countStep headerStep">
    <strong> {{ activeStep }}/{{ totalStep }} </strong>
    <p>{{ title }}</p>
  </h1>
</template>

<script>
export default {
  name: "HeaderStep",
  props: ["activeStep", "totalStep"],
  data() {
    return {
      steps: [
        {
          id: 1,
          title: "Ingreso de datos personales",
        },
        {
          id: 2,
          title: "Selección de Productos",
        },
        {
          id: 3,
          title: "Previsualización de la Cotización",
        },
      ],
    };
  },
  computed: {
    title() {
      const step = this.steps.find(e => e.id == this.activeStep);
      return step.title;
    },
  },
};
</script>
