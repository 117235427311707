<template>
  <!-- Aside -->
  <div class="aside">
    <Logo />
    <Title />
    <Description />
    <Contacts />
  </div>
</template>

<script>
import Logo from "./Logo";
import Title from "./Title";
import Description from "./Description";
import Contacts from "./Contacts";

export default {
  name: "Aside",
  components: {
    Logo,
    Title,
    Description,
    Contacts,
  },
};
</script>
