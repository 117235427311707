<template>
  <!-- Title -->
  <h2 class="title" v-text="title"></h2>
</template>

<script>
export default {
  name: "Title",
  data() {
    return {
      title: "Cotizador",
    };
  },
};
</script>
