<template>
  <!-- Contacts -->
  <div class="contacts">
    <el-row type="flex">
      <el-col
        :xs="24"
        :sm="12"
        :md="12"
        :lg="8"
        :xl="8"
        v-for="contact in contatcs"
        :key="contact.id"
      >
        <div class="contact-box-icon">
          <a class="contact-icon" :href="contact.link">
            <font-awesome-icon :icon="contact.icon" />
          </a>
        </div>
        <h3 class="contact-box-title">
          <a class="contact-box-description" :href="contact.link" v-text="contact.desc"></a>
        </h3>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "Contacts",
  data() {
    return {
      contatcs: [
        {
          id: 1,
          name: "Teléfono",
          icon: ["fas", "phone"],
          link: "tel:+56997070616",
          desc: "+56 9 9707 0616",
        },
        {
          id: 2,
          name: "E-mail",
          icon: ["fas", "envelope"],
          link: "mailto:info@pozosmanantial.cl",
          desc: "info@pozosmanantial.cl",
        },
        {
          id: 3,
          name: "Whatsapp",
          icon: ["fab", "whatsapp"],
          link: "https://wa.me/56997070616",
          desc: "+56 9 9707 0616",
        },
      ],
    };
  },
};
</script>
